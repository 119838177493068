import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import { GuestAuthContext } from '../../../modules/context/guestAuthContext';
import { useRequest } from '../../../modules/hooks';

interface generateAuthTokenResponse {
  token: string;
  expireDate: string;
}

export const GuestAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [guestToken, setGuestToken] = useState<string>('');
  const guestEndpoint = 'v1/auth/guest-auth';

  const [{ data, loading, error }, generateAuthToken] =
    useRequest<generateAuthTokenResponse>(guestEndpoint, 'post', {}, { manual: true });

  useEffect(() => {
    if (!data?.result) return;

    setGuestToken(data.result.token);
  }, [data]);

  const packageContext = useMemo(() => {
    return { token: guestToken, generateAuthToken, loading, error };
  }, [guestToken, generateAuthToken, loading, error]);

  return (
    <GuestAuthContext.Provider value={packageContext}>
      {children}
    </GuestAuthContext.Provider>
  );
};
