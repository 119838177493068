import { RefetchFunction } from 'axios-hooks';
import { createContext } from 'react';
import { AxiosError } from 'axios';
import { ArticleResponse, BaseFilterRequest, Categories } from '../../interfaces';
import { ErrorEnvelop } from '../../hooks';

export interface ReservationsContextProps {
  dataArticles: ArticleResponse | undefined;
  loadingArticles: boolean;
  errorArticles: AxiosError<ErrorEnvelop, unknown> | null;
  getArticles: RefetchFunction<unknown, unknown>;
  setFilters: React.Dispatch<React.SetStateAction<BaseFilterRequest>>;
  filters: BaseFilterRequest;
  categoriesData: Categories[];
  categoriesLoading: boolean;
  categoriesError: AxiosError<ErrorEnvelop, unknown> | null;
  getCategoriesRequest: RefetchFunction<unknown, unknown>;
}

export const ArticlesContext = createContext<ReservationsContextProps | undefined>(
  undefined,
);
