import React, { FC, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Container, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { PersonVcard } from 'react-bootstrap-icons';
import { useFormContext } from 'react-hook-form';
import { SocialNetworkCard } from '../../Cards/SocialNetworkCard';
import { useNotifications } from '../../../modules/hooks';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const IdCheckModal: FC<UserDetailsModalProps> = ({ show, handleClose, isEnd }) => {
  const { setValue, getValues } = useFormContext();
  const { setSimpleToasts } = useNotifications()!;
  const [fontIdFile, setFontIdFile] = useState<File | undefined>(undefined);
  const [backIdFile, setBackIdFile] = useState<File | undefined>(undefined);

  const frontIdInputField = useRef<HTMLInputElement>(null);
  const backIdInputField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!getValues('files') || !(backIdFile && fontIdFile)) return;
    const files = getValues('files');

    setFontIdFile(files[0]);
    setBackIdFile(files[0]);
  }, [getValues]);

  const checkFileSize = (file: File): boolean => {
    const maxSize = 10 * 1024 * 1024;

    if (file.size <= 0) {
      setSimpleToasts({
        type: 'danger',
        message: 'Files with a size of 0 bytes are invalid.',
        show: true,
      });
      return false;
    }

    if (file.size >= maxSize) {
      setSimpleToasts({
        type: 'danger',
        message: 'The file is too large. Maximum size is 10 MB.',
        show: true,
      });
      return false;
    }

    return true;
  };

  const handleFrontIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files?.[0] || undefined;
    if (files && checkFileSize(files)) {
      setFontIdFile(files);
    }
  };

  const handleBackIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files?.[0] || undefined;
    if (files && checkFileSize(files)) {
      setBackIdFile(files);
    }
  };

  const onClickSubmit = () => {
    setValue('files', [fontIdFile, backIdFile]);
    // TODO: submit user ID
    if (isEnd) isEnd(true);
    handleClose();
  };
  const ICON_SIZE = 28;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="background post-editor-modal"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <PersonVcard size={25} /> {'  '}
          Upload your ID
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Container id="guests" fluid>
          <Form>
            <Row>
              <Form.Text>
                <h6>Accepted forms of ID:</h6>

                <ul>
                  <li>Drivers license</li>
                  <li>Passport</li>
                  <li>Other government Photo ID</li>
                </ul>
              </Form.Text>
            </Row>
            <Row>
              <Form.Text>Uploaded images must not exceed 10 MB each.</Form.Text>
            </Row>

            <Row>
              <Form.Group controlId="formFile">
                <input
                  onChange={handleFrontIdChange}
                  multiple={false}
                  ref={frontIdInputField}
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  hidden
                />

                <Container
                  onClick={() => frontIdInputField.current?.click()}
                  style={{ padding: '0px', cursor: 'pointer' }}
                >
                  <SocialNetworkCard
                    title="Front of ID"
                    icon={
                      <PersonVcard
                        size={ICON_SIZE}
                        className="card-icon"
                        color={fontIdFile ? 'green' : 'white'}
                      />
                    }
                    imageUrl={fontIdFile ? URL.createObjectURL(fontIdFile) : undefined}
                  />
                </Container>

                <input
                  onChange={handleBackIdChange}
                  multiple={false}
                  ref={backIdInputField}
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  hidden
                />

                <Container
                  onClick={() => backIdInputField.current?.click()}
                  style={{ padding: '0px', cursor: 'pointer' }}
                >
                  <SocialNetworkCard
                    title="Back of ID"
                    icon={
                      <PersonVcard
                        size={ICON_SIZE}
                        className="card-icon"
                        color={backIdFile ? 'green' : 'white'}
                      />
                    }
                    imageUrl={backIdFile ? URL.createObjectURL(backIdFile) : undefined}
                  />
                </Container>
              </Form.Group>
            </Row>
          </Form>
        </Container>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button className="cancel-button no-border-radius" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          disabled={!(fontIdFile && backIdFile)}
          style={{ marginLeft: '10px' }}
          onClick={onClickSubmit}
        >
          Continue
        </Button>
      </Container>
    </Modal>
  );
};
