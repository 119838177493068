import React, { FC, useContext, useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { MultiSelect } from 'react-multi-select-component';

import 'react-datepicker/dist/react-datepicker.css';
import DropDown from '../../../assets/down-black.svg';

import {
  Option,
  PropertyRequest,
  RoleResponse,
  Roles,
  SelectType,
  Users,
  UsersForm,
} from '../../../modules/interfaces';
import './styles/styles.css';

import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';

import { PropertiesContext } from '../../../modules/context/propertiesContext';
import { config } from '../../../modules/config';
import { UserUpdateRequest } from '../UserManagementModal';
import { getApiErrorMessage } from '../../../modules/utils/transform';

type AddUsersProp = {
  show: boolean;
  onHide: () => void;
  onSave: (users?: string) => void;
  users: Users | undefined;
  isEditUsers?: boolean;
  isResetPassword?: boolean;
};

type CustomToggleProp = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CustomToggle = React.forwardRef(
  ({ children, onClick }: CustomToggleProp, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div
      ref={ref}
      className="damage-report-drop-down-style"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={DropDown} alt="drop-down" />
    </div>
  ),
);

const AddUsers: FC<AddUsersProp> = ({
  show,
  onHide,
  onSave,
  users,
  isEditUsers,
  isResetPassword,
}) => {
  const [role, setRole] = useState<string | undefined>();
  const [roleName, setRoleName] = useState<string>('');
  const { setSimpleToasts } = useNotifications()!;

  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);
  const { credentialsInfo } = useAuth()!;
  const { propertiesList, propertiesLoading } = useContext(PropertiesContext)!;
  const [roleList, setRoleList] = useState<Roles[]>([]);

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
    trigger,
    setValue,
  } = useForm<UsersForm>();

  const inviteUserUrl = `${endpoints.USERS}/invite`;
  const [{ loading: inviteLoading, data: inviteResult, error: inviteError }, inviteUser] =
    useRequest<string>(
      inviteUserUrl,
      'post',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const urlResetPassword = `${endpoints.USERS}/reset-password`;
  const [{ data: resetResult, error: resetError }, resetPassword] = useRequest<string>(
    urlResetPassword,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const url = `${endpoints.USERS}/${users?.email}`;
  const [{ loading: updateLoading, data: updateResult, error: updateError }, updateUser] =
    useRequest<UserUpdateRequest>(
      url,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const [{ data: roleData }] = useRequest<RoleResponse>(endpoints.ROLES, 'get', {
    authToken: credentialsInfo?.token,
    params: {
      currentPage: 0,
      pageCount: config.tables.balances.paginationSize,
    },
  });

  const onSelectRole = (eventKey: string | null) => {
    if (eventKey) {
      setRole(eventKey);
    }
  };

  const getColorFromStatus = (statusColor: string) => {
    if (statusColor === 'Administrator') {
      return '#2C49DF';
    }
    if (statusColor === 'Regional Manager') {
      return '#BF7927';
    }
    if (statusColor === 'Super admin') {
      return '#D04BDC';
    }
    return '#6B7280';
  };

  const GetHeaderName = () => {
    if (isResetPassword) {
      return 'Reset password';
    }
    if (isEditUsers) {
      return 'Edit user';
    }
    return 'Add new user';
  };

  const createUser = async (userData: UsersForm) => {
    const validated = isEditUsers
      ? await trigger(['email', 'role', 'firstName', 'lastName'])
      : await trigger(['email', 'role']);

    const propertiesIds: PropertyRequest[] = selectedProperties.map(item => {
      return { propertyId: item.value, isDefault: false };
    });

    if (userData && validated && !isEditUsers) {
      inviteUser({
        data: {
          email: userData.email,
          role: userData.role,
          properties: propertiesIds,
        },
      });
    }

    if (userData && validated && isEditUsers) {
      updateUser({
        data: {
          role: userData.role,
          properties: propertiesIds,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
        },
        headers: {
          authorization: credentialsInfo?.token,
        },
      });
    }
  };

  const resetPasswordHandler = async (userData: UsersForm) => {
    const validated = await trigger(['email']);

    if (validated) {
      resetPassword({
        data: { email: userData.email },
        headers: {
          authorization: credentialsInfo?.token,
        },
      });
    }
  };

  const onSubmit = (roomData: UsersForm) => {
    if (!isResetPassword) {
      createUser(roomData);
    }
    if (isResetPassword) {
      resetPasswordHandler(roomData);
    }
  };

  const closeHandler = () => {
    setSelectedProperties([]);
    setRole('');
    setRoleName('');
    reset({ email: '', firstName: '', lastName: '' });
    onHide();
  };

  const getPropertyDropDownData = () => {
    const dropDownList: SelectType[] = [];

    propertiesList?.forEach(item => {
      dropDownList.push({
        value: item.uuid,
        label: item.name,
      });
    });
    return dropDownList;
  };

  useEffect(() => {
    if (!show) return;

    if (users && isResetPassword && !isEditUsers) {
      reset({ email: users.email });
    }

    if (users && isEditUsers && !isResetPassword) {
      reset({
        email: users.email,
        firstName: users.firstName,
        lastName: users.lastName,
        role: users.roles[0].uuid,
      });
      setRole(users.roles[0].uuid);
      setRoleName(users.roles[0].name);

      const properties = users.userProperties?.map(item => ({
        value: item.property.uuid,
        label: item.property.name,
      }));

      setSelectedProperties(properties || []);
    }
  }, [users, show]);

  useEffect(() => {
    if (roleData) {
      const { result } = roleData;
      setRoleList(result.data);
    }
  }, [roleData]);

  useEffect(() => {
    register('role', { required: 'This is required' });
  }, [register]);

  useEffect(() => {
    setValue('role', role);
  }, [role]);

  useEffect(() => {
    if (resetResult || inviteResult || updateResult) {
      onSave();
      closeHandler();
    }

    if (inviteError) {
      const message = getApiErrorMessage(inviteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (resetError) {
      const message = getApiErrorMessage(resetError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [resetResult, inviteResult, updateResult, inviteError, updateError, resetError]);

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="edit-guest-modal-title">{GetHeaderName()}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label className="editor-title-label">Email address</Form.Label>
            <Form.Control
              className="editor-control"
              placeholder="Enter your email..."
              disabled={isResetPassword || isEditUsers}
              {...register('email', { required: 'This is required.' })}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <span className="error-text">{message}</span>}
            />
          </Form.Group>

          {!isResetPassword && isEditUsers && (
            <>
              <Form.Group className="mt-16px">
                <Form.Label className="editor-title-label">First name</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter your name..."
                  {...register('firstName', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Form.Group>

              <Form.Group className="mt-16px">
                <Form.Label className="editor-title-label">Last name</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter your name..."
                  {...register('lastName', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Form.Group>
            </>
          )}

          {!isResetPassword && (
            <>
              <Form.Group className="mt-16px">
                <div className="user-name-text-view">Role</div>
                <Dropdown onSelect={onSelectRole} style={{ marginBottom: '0px' }}>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                    disabled={roleList.length === 0}
                  >
                    <div className="row-status-drop-down">
                      <div
                        className="role-indicator"
                        style={{ backgroundColor: getColorFromStatus(roleName) }}
                      />
                      {roleName}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: '100%' }}>
                    {roleList.map(item => {
                      return (
                        <Dropdown.Item
                          eventKey={item.uuid}
                          onClick={() => setRoleName(item.name)}
                        >
                          <div
                            className="role-indicator"
                            style={{ backgroundColor: getColorFromStatus(item.name) }}
                          />
                          {item.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <ErrorMessage
                  errors={errors}
                  name="role"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Form.Group>

              <Form.Group className="border-bottom mt-16px">
                <Form.Group controlId="border-bottom  ">
                  <Form.Label>Properties</Form.Label>
                  <MultiSelect
                    options={getPropertyDropDownData()}
                    value={selectedProperties}
                    onChange={(values: Option[]) => {
                      setSelectedProperties(values);
                    }}
                    labelledBy="Select"
                    isLoading={propertiesLoading}
                  />
                </Form.Group>
              </Form.Group>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button className="edit-guest-modal-button" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="edit-guest-modal-button-save"
            type="submit"
            disabled={inviteLoading || updateLoading}
          >
            {inviteLoading || updateLoading ? 'Loading' : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddUsers;
