import { AxiosError } from 'axios';
import { createContext } from 'react';
import { RefetchFunction } from 'axios-hooks';
import { GuestsRequest, MewsReservation } from '../../interfaces';

interface ApiEnvelop {
  requestId: string;
  responseTimeMs: number;
}
interface SuccessEnvelop<T = unknown> extends ApiEnvelop {
  result: T;
}

export interface GuestPortalContextProps {
  reservation?: MewsReservation;
  getMewsReservations: RefetchFunction<unknown, unknown>;
  loading: boolean;
  error: AxiosError<unknown, unknown> | null;
  preregisteredGuest?: GuestsRequest;
  getGuestPreregistered: RefetchFunction<unknown, unknown>;
  guestName: string;
  setGuestName: React.Dispatch<React.SetStateAction<string>>;
  addPackageResult?: SuccessEnvelop<GuestsRequest>;
  addPackageToGuest: RefetchFunction<unknown, unknown>;
  addPackageError: AxiosError<unknown, unknown> | null;
}

export const GuestPortalContext = createContext<GuestPortalContextProps | undefined>(
  undefined,
);
