import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import LoadingOverlay from 'react-loading-overlay-ts';
import { ReservationPackageContext } from '../../../modules/context/guestPackageContext';
import { GuestPackageResponse } from '../../../modules/interfaces';
import { useAuth, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';

export const ReservationPackageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [reservationPackages, setReservationPackages] = useState<GuestPackageResponse[]>(
    [],
  );
  const { credentialsInfo } = useAuth()!;

  const URL = `${endpoints.RESERVATION_PACKAGES}`;
  const [{ data, loading }, getReservationPackage] = useRequest<GuestPackageResponse[]>(
    URL,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    if (!credentialsInfo?.token) return;

    getReservationPackage();
  }, [credentialsInfo?.token]);

  useEffect(() => {
    if (!data) return;

    setReservationPackages(data.result);
  }, [data]);

  const reservationPackageContext = useMemo(() => {
    return { reservationPackages, getReservationPackage, loading };
  }, [reservationPackages, getReservationPackage, loading]);

  return (
    <LoadingOverlay
      active={loading}
      text="Wait while we get things ready."
      className="loading-page-v"
    >
      <ReservationPackageContext.Provider value={reservationPackageContext}>
        {children}
      </ReservationPackageContext.Provider>
    </LoadingOverlay>
  );
};
