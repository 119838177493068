import { FC, useState, useEffect, useCallback, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { endpoints } from '../../../modules/mappers/urls';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { Article, ArticleResponse, Categories } from '../../../modules/interfaces';
import './styles/styles.css';

import Header from '../../Header';
import { ArticleTable } from '../../Tables/Article';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import UnCheckIcon from '../../../assets/uncheck-checkbox.svg';
import CheckIcon from '../../../assets/check-checkbox.svg';
import PlusIcon from '../../../assets/plus-icon.svg';
import AddArticles from '../../Modals/AddArticles/AddArticles';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { ArticlesContext } from '../../../modules/context/articlesContext';

interface RowArticleData {
  rowData: Article[];
  key: string;
}

interface UpdateArticleState {
  articleId: string;
  status: string;
}

export const Articles: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [modal, setModal] = useState<boolean>(false);

  const [recentData, setRecentData] = useState<Article[]>([]);
  const [pageTableData, setPageTableData] = useState<RowArticleData[]>([]);
  const [totalNumber, setTotalNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [status, setStatus] = useState<string[]>([]);
  const [category, setCategory] = useState<string[]>([]);
  const [actionArticleState, setActionArticleState] = useState<
    UpdateArticleState | undefined
  >(undefined);

  const statusArray = ['Published', 'Archived', 'Draft'];
  const [categoryShow, setCategoryShow] = useState(false);
  const categoryTarget = useRef(null);
  const [statusShow, setStatusShow] = useState(false);
  const [addArticle, setAddArticle] = useState(false);
  const [actionArticle, setActionArticle] = useState<Article | undefined>();
  const statusTarget = useRef(null);

  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const {
    dataArticles,
    loadingArticles,
    errorArticles,
    filters,
    setFilters,
    getArticles,
    categoriesData,
    getCategoriesRequest,
  } = useContext(ArticlesContext)!;

  const deleteURL = `${endpoints.ARTICLES}/${actionArticle?.uuid}`;
  const [
    { data: deleteResult, loading: deleteLoading, error: deleteError },
    deleteArticle,
  ] = useRequest<ArticleResponse>(
    deleteURL,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const changeStatusURL = `${endpoints.ARTICLES}/${actionArticleState?.articleId}`;
  const [
    { data: changeStatusResult, loading: changeStatusLoading, error: changeStatusError },
    changeStatus,
  ] = useRequest<ArticleResponse>(
    changeStatusURL,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const onDeleteArticle = () => {
    deleteArticle();
  };

  const onChangeStatus = (article: Article | undefined, articleStatus: string) => {
    if (articleStatus && article) {
      setActionArticleState({ articleId: article.uuid, status: articleStatus });
    }
  };

  const refreshHandler = useCallback(async () => {
    await getArticles({ data: { filter: filters } });
  }, [getArticles, filters]);

  useEffect(() => {
    if (!actionArticleState) return;
    const formData = new FormData();
    formData.append('status', actionArticleState.status);

    changeStatus({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: credentialsInfo?.token,
      },
    });
  }, [actionArticleState]);

  useEffect(() => {
    setLoading(loadingArticles || changeStatusLoading);
  }, [loadingArticles, changeStatusLoading, setLoading]);

  useEffect(() => {
    if (errorArticles) {
      const message = getApiErrorMessage(errorArticles);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (dataArticles) {
      const { totalCount, totalPages, data: articles = [] } = dataArticles;

      if (articles !== undefined) setRecentData(articles);
      setTotalNumber(totalCount);
      setTotalPage(totalPages);
      const articleMatrix = articles.reduce(
        (acc: RowArticleData[], article: Article): RowArticleData[] => {
          const rows = [...acc];
          const key = `row-${rows.length + 1}`;
          const rowArticle = rows.pop() || { rowData: [], key };

          if (rowArticle.rowData.length < 2) {
            rowArticle.rowData.push(article);

            rows.push(rowArticle);
          } else {
            rows.push(rowArticle, { rowData: [article], key });
          }

          return rows;
        },
        [],
      );
      setPageTableData(articleMatrix);
    }
  }, [loadingArticles, dataArticles, errorArticles, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (deleteResult) {
      getArticles();
      getCategoriesRequest();
      setModal(false);
    }
    if (changeStatusResult) {
      getArticles();
      getCategoriesRequest();
      setActionArticleState(undefined);
    }
    if (deleteError) {
      const message = getApiErrorMessage(deleteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [deleteResult, deleteError, changeStatusResult]);

  const onStatusItemClick = (statusItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = status.filter(item => item !== statusItem);
      setStatus(newList);
    } else {
      const newList = [...status];
      newList.push(statusItem);
      setStatus(newList);
    }
  };
  const onCategoryItemClick = (categoryItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = category.filter(item => item !== categoryItem);
      setCategory(newList);
    } else {
      const newList = [...category];
      newList.push(categoryItem);
      setCategory(newList);
    }
  };
  const onAddArticle = () => {
    setAddArticle(true);
  };

  const renderCategryItem = (categoryItem: Categories) => {
    const isSelected = category.includes(categoryItem.name);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onCategoryItemClick(categoryItem.name, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{categoryItem.name}</div>
      </div>
    );
  };

  const renderStatusItem = (statusItem: string) => {
    const isSelected = status.includes(statusItem);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onStatusItemClick(statusItem, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{statusItem}</div>
      </div>
    );
  };

  const onCancleStatus = () => {
    setStatus([]);
    setStatusShow(false);
  };

  const onApplyStatus = () => {
    setFilters({ ...filters, status });
    setStatusShow(false);
    getArticles();
  };

  const onCancleCategory = () => {
    setCategory([]);
    setCategoryShow(false);
  };
  const updateArticleCategories = () => {
    getArticles();
    getCategoriesRequest();
  };

  const onApplyCategory = () => {
    setFilters({ ...filters, categories: category });
    setCategoryShow(false);
    getArticles();
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title={`Articles - ${recentData.length}`} />
      <div className="artical-text-main-container">
        <div className="artical-dropdown-container ">
          <div
            ref={categoryTarget}
            onClick={() => setCategoryShow(!categoryShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              {category.length === 0 ? 'All Categories' : category[0]}
              {category.length > 1 && ' and '}
              {category.length > 1 && (
                <div className="more-text-in-dropdown">+{category.length - 1} More</div>
              )}
            </div>
            <img src={categoryShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div
            ref={statusTarget}
            onClick={() => setStatusShow(!statusShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              {status.length === 0 ? 'All Status' : status[0]}
              {status.length > 1 && ' and '}
              {status.length > 1 && (
                <div className="more-text-in-dropdown">+{status.length - 1} More</div>
              )}
            </div>
            <img src={statusShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
        </div>
        <div onClick={onAddArticle} className="add-artical-container">
          <img src={PlusIcon} alt="plus-icon" />
          <div className="add-artical-text-style">Add article</div>
        </div>
      </div>
      <Overlay
        onHide={() => setCategoryShow(false)}
        target={categoryTarget.current}
        rootClose
        show={categoryShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {categoriesData.map(renderCategryItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleCategory} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyCategory} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setStatusShow(false)}
        target={statusTarget.current}
        rootClose
        show={statusShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {statusArray.map(renderStatusItem)}
              <div className="apply-and-cancle-container">
                <Button onClick={onCancleStatus} className="cancle-text-container">
                  Cancel
                </Button>
                <Button
                  onClick={onApplyStatus}
                  className="apply-text-container"
                  disabled={deleteLoading}
                >
                  {deleteLoading ? 'Loading…' : 'Apply'}
                </Button>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <ArticleTable
        searchString=""
        tableData={recentData}
        refreshHandler={refreshHandler}
        onEditArticle={article => {
          setActionArticle(article);
          setAddArticle(true);
        }}
        onDeleteArticle={article => {
          setActionArticle(article);
          setModal(true);
        }}
        onChangeStatus={(article, articleStatus) => {
          onChangeStatus(article, articleStatus || '');
        }}
      />

      <AddArticles
        article={actionArticle}
        show={addArticle}
        key={JSON.stringify(actionArticle)}
        categories={categoriesData}
        onSave={() => setAddArticle(false)}
        onHide={() => {
          setAddArticle(false);
          setActionArticle(undefined);
        }}
        refetch={updateArticleCategories}
      />
      <DeleteModal
        show={modal}
        onDelete={onDeleteArticle}
        onHide={() => {
          setModal(false);
          setActionArticle(undefined);
        }}
        title="Delete Article"
        description="Are you sure you want to delete this article?"
      />
    </Container>
  );
};
