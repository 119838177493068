import { FunctionComponent, RefObject, useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Rating } from '@smastrom/react-rating';

import './styles/styles.css';

import { RefetchFunction } from 'axios-hooks';
import ReactStars from 'react-rating-star-with-type';
import AddReportIcon from '../../assets/add-report.svg';

import EditNoteIcon from '../../assets/edit-note.svg';

import QuoteIcon from '../../assets/quote.svg';
import RoomIcon from '../../assets/room.svg';
import {
  GuestPackageResponse,
  MewsReservation,
  PriceByReservationResponse,
} from '../../modules/interfaces';
import AddReseravtionNotes from '../Modals/AddReseravtionNotes/AddReseravtionNotes';
import { useAuth, useLoading, useNotifications, useRequest } from '../../modules/hooks';
import { endpoints } from '../../modules/mappers/urls';
import { RoomItem } from './RoomItem';
import { ReservationPackageContext } from '../../modules/context/guestPackageContext';

type StayDetailProps = {
  checkIn: string;
  checkOut: string;
  reservation: MewsReservation;
  isPrevious?: boolean;
  addOnPackage?: RefObject<HTMLDivElement>;
  guestPackages?: GuestPackageResponse[] | undefined;
  pricesReservation?: PriceByReservationResponse | undefined;
  vehicleNumber?: string;
  getMewsReservation?: RefetchFunction<unknown, unknown>;
};

const StayDetail: FunctionComponent<StayDetailProps> = ({
  checkIn,
  checkOut,
  reservation,
  isPrevious,
  addOnPackage,
  guestPackages,
  pricesReservation,
  vehicleNumber,
  getMewsReservation,
}: StayDetailProps) => {
  const [reservationNote, setReservationNote] = useState('');
  const [guestPackageId, setGuestPackageId] = useState('');
  const { setSimpleToasts } = useNotifications()!;
  const [status, setStatus] = useState('');
  const { setLoading } = useLoading()!;
  const [statusIndex, setStatusIndex] = useState<number | undefined>();

  const [addReservationModal, setAddReservationModal] = useState(false);

  const { credentialsInfo } = useAuth()!;

  const CustomStar = (
    <path d="M9.61729 8.56227L2.17396 9.64143L2.04213 9.66827C1.84256 9.72125 1.66062 9.82624 1.5149 9.97253C1.36918 10.1188 1.2649 10.3012 1.2127 10.5009C1.1605 10.7007 1.16226 10.9108 1.21779 11.1097C1.27333 11.3085 1.38065 11.4891 1.52879 11.6329L6.92113 16.8818L5.64946 24.2959L5.63429 24.4243C5.62208 24.6307 5.66494 24.8366 5.75848 25.021C5.85203 25.2054 5.99291 25.3616 6.16668 25.4737C6.34046 25.5858 6.5409 25.6496 6.74747 25.6587C6.95404 25.6678 7.15932 25.6219 7.34229 25.5256L13.9993 22.0256L20.6411 25.5256L20.7578 25.5793C20.9504 25.6551 21.1596 25.6784 21.3642 25.6467C21.5687 25.6149 21.7611 25.5294 21.9216 25.3987C22.0822 25.2681 22.2051 25.0971 22.2778 24.9033C22.3504 24.7095 22.3702 24.4999 22.3351 24.2959L21.0623 16.8818L26.457 11.6318L26.548 11.5326C26.6779 11.3725 26.7632 11.1808 26.795 10.977C26.8267 10.7733 26.804 10.5647 26.7289 10.3726C26.6538 10.1805 26.5292 10.0118 26.3677 9.88353C26.2062 9.75529 26.0136 9.67216 25.8095 9.6426L18.3661 8.56227L15.0388 1.81893C14.9425 1.62356 14.7935 1.45903 14.6085 1.34399C14.4236 1.22894 14.2101 1.16797 13.9923 1.16797C13.7745 1.16797 13.561 1.22894 13.3761 1.34399C13.1911 1.45903 13.0421 1.62356 12.9458 1.81893L9.61729 8.56227Z" />
  );

  const myStyles = {
    itemShapes: CustomStar,
    itemStrokeWidth: 2,
    activeStrokeColor: '#F19204',
    activeFillColor: '#F19204',
    inactiveFillColor: 'white',
    inactiveStrokeColor: '#E9E9E9',
    orientation: 'horizontal',
  };
  const onAddReservationHide = () => {
    setAddReservationModal(false);
  };

  const onAddReservation = () => {
    setAddReservationModal(true);
  };

  const onReservationNoteUpdate = (note: string) => {
    setReservationNote(note);
    setAddReservationModal(false);
  };

  const URL = `${endpoints.RESERVATION_PACKAGES}/${guestPackageId}`;
  const [
    { data: guestPackageData, loading: loadingGuestPackage, error: errorGuestPackage },
    updateReservationPackageState,
  ] = useRequest<GuestPackageResponse>(
    URL,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const [packages, setPackages] = useState<GuestPackageResponse[]>([]);

  const onConfirm = (index: number, action: string) => {
    setPackages(prevPackages => {
      const newPackage = prevPackages.map((packageItem, j) => {
        const newPackageItem = { ...packageItem };
        if (index === j) {
          newPackageItem.status = action;
        }
        return newPackageItem;
      });
      return newPackage;
    });
  };

  const onSubmit = (reservationStatus: string, reservationId: string, index: number) => {
    setGuestPackageId(reservationId);
    setStatus(reservationStatus);
    setStatusIndex(index);
  };

  const reservationPackageList = useContext(ReservationPackageContext);
  const { getReservationPackage } = reservationPackageList!;

  useEffect(() => {
    if (!status) return;

    updateReservationPackageState({
      data: { status },
      headers: {
        authorization: credentialsInfo?.token,
      },
    });

    setStatus('');
  }, [status]);

  useEffect(() => {
    if (!guestPackages) return;

    setPackages(guestPackages);
  }, [guestPackages]);

  useEffect(() => {
    setLoading(loadingGuestPackage);
  }, [loadingGuestPackage]);

  useEffect(() => {
    if (statusIndex === undefined || loadingGuestPackage) return;

    if (
      guestPackageData?.result.status &&
      guestPackageData.result.status !== 'PENDING' &&
      getMewsReservation &&
      !errorGuestPackage
    ) {
      getMewsReservation();
      getReservationPackage();
      onConfirm(statusIndex, guestPackageData.result.status);
    }

    if (errorGuestPackage) {
      setSimpleToasts({
        message:
          errorGuestPackage.response?.data.error.message || 'Invalid card assigned',
        type: 'danger',
        show: true,
      });

      updateReservationPackageState({
        data: { status: 'FAILED' },
        headers: {
          authorization: credentialsInfo?.token,
        },
      });
    }
  }, [guestPackageData, errorGuestPackage, loadingGuestPackage]);

  const formatDate = (date: string) => {
    return DateTime.fromISO(date).toFormat('LLL, dd');
  };

  function getAdultsAndChildren(): string {
    let adults = 0;
    let children = 0;
    reservation.PersonCounts?.forEach(personCount => {
      const ageCategory = reservation?.AgeCategory?.AgeCategories.find(
        ageCategoryItem => ageCategoryItem.Id === personCount.AgeCategoryId,
      );

      if (ageCategory?.MaximalAge === 18) {
        children += 1;
      } else {
        adults += 1;
      }
    });

    let adultString = '';

    if (adults > 1) {
      adultString = 'Adults';
    } else if (adults === 1) {
      adultString = 'Adult';
    }

    return children > 0
      ? `${adults} ${adultString}, ${children} children`
      : `${adults} ${adultString}`;
  }

  function failedStatusString(failedStatus: string): string {
    return failedStatus.charAt(0) + failedStatus.slice(1).toLowerCase();
  }

  const renderPackageTable = () => {
    return (
      <div ref={addOnPackage} className="bdr package-table-container">
        <table className="table">
          <thead className="bg-red">
            <tr>
              <th scope="col">
                <div className="table-header-title table-padding-left">package name</div>
              </th>
              <th scope="col">
                <div className="table-header-title">Unit Price</div>
              </th>
              <th scope="col">
                <div className="table-header-title">status</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {packages.map((item, index) => {
              const lastIndex = index === packages.length - 1;
              return (
                <tr
                  key={item.uuid}
                  className={lastIndex ? 'last-row-border-hide' : undefined}
                >
                  <td valign="middle">
                    <div className="table-cell-package-text table-padding-left">
                      {item.packages.name}
                    </div>
                    <div className="table-cell-text-package-desc table-padding-left">
                      {item.description}
                    </div>
                  </td>
                  <td valign="middle">
                    <div className="table-cell-price-text">{item.price}</div>
                  </td>
                  <td valign="middle" className="package-add-on-button-td">
                    {item.status === 'PENDING' && !isPrevious ? (
                      <div className="package-table-btn-container">
                        <div
                          onClick={() => {
                            onSubmit('APPROVED', item.uuid, index);
                          }}
                          className="confirm-button-package"
                        >
                          Confirm
                        </div>
                        <div
                          onClick={() => {
                            onSubmit('DENIED', item.uuid, index);
                          }}
                          className="confirm-button-package deny-button-package"
                        >
                          Deny
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          item.status === 'APPROVED'
                            ? 'approved-button-package'
                            : 'approved-button-package denied-button-package'
                        }
                      >
                        {item.status === 'APPROVED'
                          ? 'Approved'
                          : failedStatusString(item.status || 'Denied')}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderPrice = () => {
    return (
      <div className="price-reservation-container-previous-stay">
        <div className="price-info-container">
          <div className="price-info-title">Total Room Charges</div>
          <div className="price-info-value">{`$${pricesReservation?.subtotal.toFixed(
            2,
          )}`}</div>
        </div>
        {pricesReservation?.taxes &&
          pricesReservation.taxes.map(item => (
            <div key={item.code} className="price-info-container">
              <div className="price-info-title">{item.name}</div>
              <div className="price-info-value">{`$${item.value.toFixed(2)}`}</div>
            </div>
          ))}

        <div className="price-info-container">
          <div className="price-info-title">Add-On Purchases</div>
          <div className="price-info-value">
            {`$${(
              (pricesReservation?.reservationTotal || 0) - (pricesReservation?.total || 0)
            ).toFixed(2)}`}
          </div>
        </div>

        <div className="price-info-divider" />
        <div
          className="price-info-divider"
          style={{ marginTop: '5px', marginBottom: '10px' }}
        />
        <div className="price-info-container">
          <div className="price-info-total">Total</div>
          <div className="price-info-total-value">
            {pricesReservation?.reservationTotal.toFixed(2)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="stay-detail-container"
      style={{
        border: isPrevious ? '0px' : undefined,
        padding: isPrevious ? '0px' : undefined,
      }}
    >
      {isPrevious && <div className="devider-previous-hotel-stay" />}
      {!isPrevious && (
        <div className="hotel-detail-container">
          <img className="hotel-image" alt="room" src={RoomIcon} />
          <div className="hotel-name-container">
            {reservation.Resource ? (
              <div className="hotel-name">
                {`${reservation.Resource?.Name} - ${reservation.Resource?.ResourceName}`}
              </div>
            ) : (
              <div className="no-room-text">There is no room assigned</div>
            )}

            <div className="hotel-details">284 Lafayette St Salem Ma, 01970</div>
          </div>
        </div>
      )}
      <div
        className="hotel-information-container"
        style={{ marginTop: isPrevious ? '0px' : undefined }}
      >
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Reservation ID</div>
          <div className="hotel-info-value">{reservation.Id}</div>
        </div>
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Number of guests</div>
          <div className="hotel-info-value">{getAdultsAndChildren()}</div>
        </div>
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Reservation days</div>
          <div className="hotel-info-value">
            {formatDate(checkIn)} - {formatDate(checkOut)}
          </div>
        </div>
      </div>
      <div className="hotel-information-container">
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Purpose of stay</div>
          <div className="hotel-info-value">{reservation.Purpose || 'Not specified'}</div>
        </div>
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Booking channel</div>
          <div className="hotel-info-value">
            {reservation.CommanderOrigin !== 'Channel'
              ? reservation?.CommanderOrigin
              : reservation.Company?.Name || 'Not specified'}
          </div>
        </div>
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Rate Plan</div>
          <div className="hotel-info-value" style={{ color: '#D17E00' }}>
            Non-refundable
          </div>
        </div>
      </div>
      <div className="hotel-information-container">
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Vehicle number</div>
          <div className="hotel-info-value">{vehicleNumber || 'Not specified'}</div>
        </div>
        <div className="hotel-information-title-container">
          <div className="hotel-info-title">Agreement</div>
          <div
            className="hotel-info-value"
            style={{ color: '#2651C1', cursor: 'pointer' }}
          >
            Verification Report.pdf
          </div>
        </div>
        <div className="hotel-information-title-container" />
      </div>
      <div className="devider-hotel-stay" />
      {reservation.Resource ? (
        <>
          <div className="hotel-section-title">Booked Rooms & Door Access Control</div>
          <div className="room-list-container">
            {reservation && (
              <RoomItem
                roomItem={reservation}
                reservationId={reservation.Id}
                isPrevious={isPrevious}
              />
            )}
          </div>
        </>
      ) : (
        <div className="bdr package-table-container">
          <div className="no-reservation-note-text">
            This reservation does not have an assigned room.
          </div>
        </div>
      )}

      <div className="hotel-section-title1">Special Requests</div>
      <div className="text-info-container">
        <img alt="quote" src={QuoteIcon} />
        <div className="special-request-text">
          {`"Hi there! We're looking forward to our stay with you. We'll be
        celebrating our anniversary, so if there's any way to add a special touch or
        surprise in the room, that would be wonderful. Thanks in advance"`}
        </div>
      </div>
      <div className="hotel-section-title1 package-table-btn-container">
        Reservation Notes
        <div onClick={onAddReservation}>
          <img
            className="add-package-report-icon"
            src={reservationNote ? EditNoteIcon : AddReportIcon}
            alt="add-report"
          />
        </div>
      </div>
      <div className="text-info-container">
        {reservationNote ? (
          <div className="reservation-note-text">{reservationNote}</div>
        ) : (
          <div className="no-reservation-note-text">No reservation notes yet</div>
        )}
      </div>

      <div className="hotel-section-title1">Add-On packages</div>

      {packages.length !== 0 ? (
        renderPackageTable()
      ) : (
        <div className="no-reservation-note-text">No package was selected</div>
      )}

      {pricesReservation && (
        <>
          <div className="hotel-section-title1">Price</div>
          {renderPrice()}
        </>
      )}

      <div className="hotel-section-title1">Guest Rating</div>
      <div className="guest-rating-conatiner">
        <div className="star-container">
          <div className="rating-style">{`${(reservation.Rating || 0).toFixed(1)}`}</div>
          <div className="vertical-line-divider" />
          <Rating value={reservation.Rating || 0} itemStyles={myStyles} readOnly />
        </div>
        {reservation.Rating === 0 && (
          <div className="guest-rate-text">Not yet rated by a guest</div>
        )}
      </div>

      <AddReseravtionNotes
        show={addReservationModal}
        onHide={onAddReservationHide}
        onSave={onReservationNoteUpdate}
        note={reservationNote}
      />
    </div>
  );
};

export default StayDetail;
