/* eslint-disable no-nested-ternary */
import React, { FC, ReactNode } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './styles/styles.css';

interface ReservationCardProps {
  title: ReactNode;
  icon: ReactNode;
  showModal?: React.Dispatch<React.SetStateAction<boolean>>;
  checked?: boolean;
  disabled?: boolean;
}

export const ReservationCard: FC<ReservationCardProps> = ({
  title,
  icon,
  showModal,
  checked = false,
  disabled = false,
}) => {
  return (
    <Card
      className="icon-card"
      style={{ cursor: 'pointer' }}
      onClick={() => (showModal && !disabled ? showModal(true) : {})}
    >
      <Card.Body>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col lg="9" xs="10" className="card-title">
            {icon}
            <span style={{ marginLeft: '12px' }}> {title} </span>
          </Col>
          <Col lg="3" xs="2" style={{ display: 'flex', justifyContent: 'right' }}>
            {showModal ? (
              !checked ? null : (
                <CheckCircleFill size={16} color="#2ce983" />
              )
            ) : null}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
