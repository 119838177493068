import { FC, ReactNode } from 'react';
import { BoxArrowInLeft, BoxArrowInRight, Lock } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import './styles/styles.css';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { GuestPackage } from '../../../modules/interfaces';

interface ReservationCardProps {
  title: string;
  content: ReactNode;
  type?: 'checkIn' | 'checkOut';
  active?: boolean;
  data?: GuestPackage[];
  contentCheckIn?: ReactNode;
  titleCheckIn?: string;
  checkOutFunction?: () => void;
}

function renderLockIcon(type: string) {
  if (type !== 'checkOut') {
    return (
      <Container className="checkin-unactive-container">
        <Lock style={{ margin: 'auto' }} size={27} className="icon" />
      </Container>
    );
  }
  return null;
}

export const CheckInCard: FC<ReservationCardProps> = ({
  title,
  content,
  type = 'checkIn',
  active = false,
  data,
  contentCheckIn = '',
  titleCheckIn = '',
  checkOutFunction,
}) => {
  const navigate = useNavigate();
  const { reservationId } = useParams();
  return (
    <Card className="icon-check-in-card">
      <Row className="card-row no-padding-row">
        {type !== 'checkOut' || !contentCheckIn ? (
          <Col lg="10" sm="10" xs="9">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span className="mb-5px card-check-text">{title}</span>
              <span className="card-text-content"> {content}</span>
            </div>
          </Col>
        ) : (
          <>
            <Col lg="4" sm="4" xs="4">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span className="mb-5px card-check-text">{titleCheckIn}</span>
                <span className="card-text-content"> {contentCheckIn}</span>
              </div>
            </Col>
            <Col
              lg="2"
              sm="2"
              xs="1"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <hr
                style={{
                  marginTop: '0px',
                  height: '100%',
                  width: '1px',
                  border: 'none',
                  backgroundColor: '#979797',
                }}
              />
            </Col>
            <Col lg="4" sm="4" xs="4">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span className="mb-5px card-check-text-right">{title}</span>
                <span className="card-text-content-right"> {content}</span>
              </div>
            </Col>
          </>
        )}
        <Col
          lg="2"
          sm="2"
          xs="3"
          style={{
            marginRight: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {active ? (
            <Container
              onClick={() =>
                type === 'checkOut' && checkOutFunction
                  ? checkOutFunction()
                  : navigate(`/guest-registration/${reservationId}`, {
                      state: data || [],
                    })
              }
              className="checkin-icon-container"
            >
              {type === 'checkIn' ? (
                <BoxArrowInRight style={{ margin: 'auto' }} size={27} className="icon" />
              ) : (
                <BoxArrowInLeft style={{ margin: 'auto' }} size={27} className="icon" />
              )}
            </Container>
          ) : (
            renderLockIcon(type)
          )}
        </Col>
      </Row>
    </Card>
  );
};
