import { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Button, Col, Row } from 'react-bootstrap';
import { Coin } from 'react-bootstrap-icons';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../modules/config';

import './styles/styles.css';
import {
  useLoading,
  useNotifications,
  RequestOptions,
  useRequest,
  useGuestAuth,
} from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';

interface SetupIntent {
  object: string;
  customer?: string;
  sessionClientSecret: string;
  setupClientSecret: string;
}

interface BaseCheckoutProps {
  handleModalClose?: () => void;

  setupClientSecret: string;
  reservationId: string;
  onRequestSubmit?: () => void;
}

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  customerId: string;
  reservationId: string;
  onRequestSubmit?: () => void;
}

const stripePromise = loadStripe(config.stripe.publicKey);

const InnerForm: FC<BaseCheckoutProps> = ({
  handleModalClose,
  setupClientSecret,
  reservationId,
  onRequestSubmit,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setSimpleToasts } = useNotifications()!;
  const [paymentLoading, setPaymentLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setPaymentLoading(false);

    if (handleModalClose) {
      handleModalClose();
    }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setPaymentLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      setSimpleToasts({ message: 'Stripe is not ready', type: 'danger', show: true });

      if (handleModalClose) {
        handleModalClose();
      }

      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setSimpleToasts({
        message: submitError.message || 'Unable to capture payment method', // cambiar
        type: 'danger',
        show: true,
      });
    }

    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret: setupClientSecret,
      redirect: 'if_required',
    });

    if (error) {
      setSimpleToasts({
        message: error.message || 'Unable to capture payment method',
        type: 'danger',
        show: true,
      });
      setPaymentLoading(false);
    } else {
      setSimpleToasts({
        message: 'Payment added successfully',
        type: 'success',
        show: true,
      });
      handleClose();
      if (!onRequestSubmit) {
        navigate(`/guest-portal/${reservationId}`);
      } else {
        onRequestSubmit();
      }
    }
  };

  return (
    <>
      <Row>
        <PaymentElement />
      </Row>
      <Row
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          display: 'flex',
          justifyContent: 'center',
          marginTop: 38,
        }}
      >
        <Col xs="auto" sm="auto">
          <Button
            className="cancel-button no-border-radius"
            onClick={() => {
              if (handleModalClose) {
                handleModalClose();
              }
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col xs="auto" sm="auto">
          <Button
            onClick={e => handleSubmit(e)}
            className="save-button no-border-radius"
            disabled={!stripe || !elements || paymentLoading}
          >
            {paymentLoading ? 'Loading…' : 'Save Card'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const CardForPayPackagesModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  customerId,
  reservationId,
  onRequestSubmit,
}) => {
  const { token } = useGuestAuth()!;

  const { setSimpleToasts } = useNotifications()!;
  const loadingHook = useLoading()!;
  const url = endpoints.CREATE_CUSTOMER_SESSION;

  const requestOpts = useMemo<RequestOptions>(() => {
    const payload = {
      customerId,
      reservationId,
    };

    return {
      authGuestToken: token,
      payload,
    };
  }, [token, customerId, reservationId]);

  const [{ data, error, loading }, getCustomerSession] = useRequest<SetupIntent>(
    url,
    'post',
    requestOpts,
    { manual: true },
  );

  const [options, setOptions] = useState<StripeElementsOptions>();

  useEffect(() => {
    if (!show) return;
    getCustomerSession();
  }, [show]);

  useEffect(() => {
    if (loading) return;

    if (error) {
      setSimpleToasts({ type: 'danger', message: error.message, show: true });
    }

    if (data) {
      const { result } = data;
      setOptions({
        customerSessionClientSecret: result.sessionClientSecret,
        mode: 'setup',
        setup_future_usage: 'off_session',
        currency: 'usd',
        payment_method_types: ['card'],
        appearance: {
          theme: 'night',
          rules: {
            '.Input': {
              backgroundColor: '#0f0f0f',
              border: '0px',
            },
          },
        },
      });
    }
  }, [loading, error, data, loadingHook, setSimpleToasts]);

  return (
    <LoadingOverlay active={loading} text="Wait while we get things ready.">
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="post-editor-modal modal-200w"
        backdrop="static"
        id="guest-portal"
      >
        <Modal.Header>
          <Modal.Title className="editor-title">
            <Coin size={25} /> Pay for requested package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {options && data?.result.setupClientSecret ? (
            <Elements stripe={stripePromise} options={options}>
              <InnerForm
                handleModalClose={handleClose}
                setupClientSecret={data.result.setupClientSecret}
                reservationId={reservationId}
                onRequestSubmit={onRequestSubmit}
              />
            </Elements>
          ) : null}
        </Modal.Body>
      </Modal>
    </LoadingOverlay>
  );
};
