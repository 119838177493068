import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Container, Row } from 'react-bootstrap';

import { FileText } from 'react-bootstrap-icons';
import './styles/styles.css';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
}

export const TermsAndPrivacy: FC<UserDetailsModalProps> = ({ show, handleClose }) => {
  const onClickSubmit = () => {
    // TODO: submit user signature
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} />
          Terms & Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Form.Text>
              <div
                style={{
                  maxHeight: '61vh',
                  overflowY: 'auto',
                }}
              >
                <h6 className="text-white">Terms & Conditions:</h6>
                <ol>
                  <li>
                    Booking Confirmation: By making a reservation, you agree to the terms
                    and conditions of our hotel.
                  </li>
                  <li>
                    Check-in & Check-out: Check-in time is at [insert time] and check-out
                    time is at [insert time].
                  </li>
                  <li>
                    Payment: Payment is required at the time of booking or upon check-in.
                    We accept [list accepted payment methods].
                  </li>
                  <li>
                    Cancellation: Cancellation policies vary based on the room type and
                    rate plan selected. Please refer to your booking confirmation for
                    details.
                  </li>
                  <li>
                    Guest Conduct: We expect all guests to respect fellow guests and hotel
                    staff. Any disruptive or inappropriate behavior may result in eviction
                    without a refund.
                  </li>
                  <li>
                    Damages: Guests are responsible for any damages to the hotel property
                    and will be charged accordingly.
                  </li>
                </ol>

                <h6 className="text-white">Privacy Policy:</h6>
                <ol>
                  <li>
                    Information Collection: We collect personal information for
                    reservations, including name, contact details, and payment
                    information.
                  </li>
                  <li>
                    Data Security: Your information is stored securely, and we take
                    measures to protect it from unauthorized access.
                  </li>
                  <li>
                    Use of Information: We use your data for booking purposes,
                    communication, and to improve our services. We do not share your
                    information with third parties without your consent.
                  </li>
                  <li>
                    Cookies: We use cookies to enhance your online experience on our
                    website. You can manage your cookie preferences in your browser
                    settings.
                  </li>
                  <li>
                    Marketing Communications: With your consent, we may send you
                    promotional offers and newsletters. You can opt out at any time.
                  </li>
                  <li>
                    Third-Party Links: Our website may contain links to third-party sites.
                    We are not responsible for their privacy practices, so please review
                    their policies.
                  </li>
                  <li>
                    Data Rights: You have the right to access, correct, or delete your
                    personal information. Contact us for assistance.
                  </li>
                  <li>
                    Updates: We may update our privacy policy periodically. Check for the
                    latest version on our website.
                  </li>
                </ol>

                <p>
                  Please note that these are simplified terms and policies. It&apos;s
                  essential to consult with legal experts to create comprehensive and
                  legally compliant documents for your hotel.
                </p>
              </div>
            </Form.Text>
          </Row>
        </Form>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button
          className="save-button no-border-radius"
          onClick={onClickSubmit}
          style={{ marginLeft: '10px' }}
        >
          Agree & Continue
        </Button>
      </Container>
    </Modal>
  );
};
