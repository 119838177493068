import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import {
  ArticleResponse,
  BaseFilterRequest,
  Categories,
} from '../../../modules/interfaces';

import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';

import { endpoints } from '../../../modules/mappers/urls';
import { ArticlesContext } from '../../../modules/context/articlesContext';

import { getApiErrorMessage } from '../../../modules/utils/transform';

export const ArticlesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dataArticles, setDataArticles] = useState<ArticleResponse>();
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;
  const [categoriesData, setCategoriesData] = useState<Categories[]>([]);
  const [filters, setFilters] = useState<BaseFilterRequest>({
    name: '',
    categories: [],
    status: [],
  });

  const [{ data, loading: loadingArticles, error: errorArticles }, getArticles] =
    useRequest<ArticleResponse>(endpoints.ARTICLES, 'get', {
      authToken: credentialsInfo?.token,
      params: {
        name: filters.name,
        categories: filters.categories.length === 0 ? '' : filters.categories.join(','),
        status: filters.status.length === 0 ? '' : filters.status.join(','),
        fetchRecent: true,
        currentPage: 0,
        pageCount: 0,
      },
    });

  const [
    { data: categories, loading: categoriesLoading, error: categoriesError },
    getCategoriesRequest,
  ] = useRequest<Categories[]>(
    endpoints.CATEGORIES,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  useEffect(() => {
    if (!credentialsInfo?.token) return;

    getCategoriesRequest();
  }, [credentialsInfo?.token]);

  useEffect(() => {
    if (!data) return;

    setDataArticles(data?.result);
  }, [data]);

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);

      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categories) {
      const { result } = categories;
      setCategoriesData(result);
    }
  }, [categoriesLoading, categories, categoriesError]);

  const reservationPackageContext = useMemo(() => {
    return {
      dataArticles,
      loadingArticles,
      errorArticles,
      getArticles,
      setFilters,
      filters,
      categoriesData,
      categoriesLoading,
      categoriesError,
      getCategoriesRequest,
    };
  }, [
    dataArticles,
    loadingArticles,
    errorArticles,
    getArticles,
    setFilters,
    filters,
    categoriesData,
    categoriesLoading,
    categoriesError,
    getCategoriesRequest,
  ]);

  return (
    <LoadingOverlay
      active={loadingArticles && !!credentialsInfo?.token}
      text="Wait while we get things ready"
      className="loading-page-v"
    >
      <ArticlesContext.Provider value={reservationPackageContext}>
        {children}
      </ArticlesContext.Provider>
    </LoadingOverlay>
  );
};
